@font-face {
  font-family: 'pg-icon';
  src: url('Assets/fonts/new-pg-icons.ttf');
  src: url('Assets/fonts/new-pg-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.pg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pg-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-PG_Add-icon:before {
  content: "\e900";
}
.icon-PG_Android-icon:before {
  content: "\e901";
}
.icon-PG_Arrow-back-icon:before {
  content: "\e902";
}
.icon-PG_Arrow-next-icon:before {
  content: "\e903";
}
.icon-PG_Arrow-up-icon:before {
  content: "\e904";
}
.icon-PG_Article-icon:before {
  content: "\e905";
}
.icon-PG_Attach-icon:before {
  content: "\e906";
}
.icon-PG_Bag-icon:before {
  content: "\e907";
}
.icon-PG_Bell-icon:before {
  content: "\e908";
}
.icon-PG_Browser-icon:before {
  content: "\e909";
}
.icon-PG_Calendar-icon:before {
  content: "\e90a";
}
.icon-PG_Camera-icon:before {
  content: "\e90b";
}
.icon-PG_Caption-icon:before {
  content: "\e90c";
}
.icon-PG_Card-AMEX-icon .path1:before {
  content: "\e90d";
  color: rgb(48, 111, 183);
}
.icon-PG_Card-AMEX-icon .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Card-AMEX-icon .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(48, 111, 183);
}
.icon-PG_Card-AMEX-icon .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(48, 111, 183);
}
.icon-PG_Card-AMEX-icon .path5:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Card-AMEX-icon .path6:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(48, 111, 183);
}
.icon-PG_Card-AMEX-icon .path7:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(48, 111, 183);
}
.icon-PG_Card-Discover-icon .path1:before {
  content: "\e914";
  color: rgb(255, 255, 255);
}
.icon-PG_Card-Discover-icon .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(224, 225, 224);
}
.icon-PG_Card-Discover-icon .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(242, 97, 34);
}
.icon-PG_Card-Discover-icon .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(29, 15, 18);
}
.icon-PG_Card-Discover-icon .path5:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(242, 97, 34);
}
.icon-PG_Card-fill-icon .path1:before {
  content: "\e919";
  color: rgb(1, 1, 1);
}
.icon-PG_Card-fill-icon .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Card-fill-icon .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Card-fill-icon .path4:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Card-MasterCard-icon .path1:before {
  content: "\e91d";
  color: rgb(38, 37, 37);
}
.icon-PG_Card-MasterCard-icon .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(234, 29, 37);
}
.icon-PG_Card-MasterCard-icon .path3:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(246, 158, 30);
}
.icon-PG_Card-MasterCard-icon .path4:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(242, 97, 34);
}
.icon-PG_Card-outline-icon:before {
  content: "\e921";
}
.icon-PG_Card-Visa-icon .path1:before {
  content: "\e922";
  color: rgb(255, 255, 255);
}
.icon-PG_Card-Visa-icon .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(224, 225, 224);
}
.icon-PG_Card-Visa-icon .path3:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(41, 42, 104);
}
.icon-PG_CC-icon .path1:before {
  content: "\e925";
  color: rgb(255, 255, 255);
}
.icon-PG_CC-icon .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_CC-icon .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Chat-icon-79:before {
  content: "\e928";
}
.icon-PG_Chat-icon-80:before {
  content: "\e929";
}
.icon-PG_Check-off-icon:before {
  content: "\e92a";
}
.icon-PG_Check-on-icon:before {
  content: "\e92b";
}
.icon-PG_Checkbox-close-icon:before {
  content: "\e92c";
}
.icon-PG_Checkbox-icon:before {
  content: "\e92d";
}
.icon-PG_Checkbox-off-icon:before {
  content: "\e92e";
}
.icon-PG_Checkbox-on-icon:before {
  content: "\e92f";
}
.icon-PG_Clicks-icon:before {
  content: "\e930";
}
.icon-PG_Close-icon:before {
  content: "\e931";
}
.icon-PG_Clubhouse-icon-145:before {
  content: "\e932";
}
.icon-PG_Clubhouse-icon-158:before {
  content: "\e933";
}
.icon-PG_Code-icon:before {
  content: "\e934";
}
.icon-PG_Collapse-icon:before {
  content: "\e935";
}
.icon-PG_Color-icon:before {
  content: "\e936";
}
.icon-PG_Content-icon .path1:before {
  content: "\e937";
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path3:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path4:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path5:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path6:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path7:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path8:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path9:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path10:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Content-icon .path11:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Content-icon .path12:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Down-icon-86:before {
  content: "\e943";
}
.icon-PG_Down-icon-106:before {
  content: "\e944";
}
.icon-PG_Download-icon:before {
  content: "\e945";
}
.icon-PG_Downwards-icon:before {
  content: "\e946";
}
.icon-PG_Drag-icon:before {
  content: "\e947";
}
.icon-PG_Duplicate-icon:before {
  content: "\e948";
}
.icon-PG_Edit-icon-81:before {
  content: "\e949";
}
.icon-PG_Edit-icon-82:before {
  content: "\e94a";
}
.icon-PG_Editor-justify-icon:before {
  content: "\e94b";
}
.icon-PG_Editor-left-icon:before {
  content: "\e94c";
}
.icon-PG_Editor-right-icon:before {
  content: "\e94d";
}
.icon-PG_Ellipses-icon:before {
  content: "\e94e";
}
.icon-PG_Email-icon:before {
  content: "\e94f";
}
.icon-PG_External-link-icon:before {
  content: "\e950";
}
.icon-PG_Facebook-icon-141:before {
  content: "\e951";
}
.icon-PG_Facebook-icon-166:before {
  content: "\e952";
}
.icon-PG_Filter-icon:before {
  content: "\e953";
}
.icon-PG_Font-bold-icon:before {
  content: "\e954";
}
.icon-PG_Font-color-icon .path1:before {
  content: "\e955";
  color: rgb(1, 1, 1);
}
.icon-PG_Font-color-icon .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(98, 99, 173);
}
.icon-PG_Font-italic-icon:before {
  content: "\e957";
}
.icon-PG_Font-size-icon:before {
  content: "\e958";
}
.icon-PG_Font-underline-icon:before {
  content: "\e959";
}
.icon-PG_Forward-icon:before {
  content: "\e95a";
}
.icon-PG_Fullscreen-icon:before {
  content: "\e95b";
}
.icon-PG_Globe-fill-icon .path1:before {
  content: "\e95c";
  color: rgb(1, 1, 1);
}
.icon-PG_Globe-fill-icon .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Globe-fill-icon .path3:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Globe-outline-icon:before {
  content: "\e95f";
}
.icon-PG_Google-icon .path1:before {
  content: "\e960";
  color: rgb(249, 188, 21);
}
.icon-PG_Google-icon .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(234, 69, 53);
}
.icon-PG_Google-icon .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(54, 168, 82);
}
.icon-PG_Google-icon .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(85, 126, 191);
}
.icon-PG_Google-Meet-icon .path1:before {
  content: "\e964";
  color: rgb(54, 168, 82);
}
.icon-PG_Google-Meet-icon .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(54, 168, 82);
}
.icon-PG_Google-Meet-icon .path3:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(3, 125, 63);
}
.icon-PG_Google-Meet-icon .path4:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(249, 188, 21);
}
.icon-PG_Google-Meet-icon .path5:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(234, 69, 53);
}
.icon-PG_Google-Meet-icon .path6:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(85, 126, 191);
}
.icon-PG_Google-Meet-icon .path7:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(30, 100, 175);
}
.icon-PG_Grid-icon:before {
  content: "\e96b";
}
.icon-PG_Handle-icon:before {
  content: "\e96c";
}
.icon-PG_Home-fill-icon:before {
  content: "\e96d";
}
.icon-PG_Home-outline-icon:before {
  content: "\e96e";
}
.icon-PG_Image-icon:before {
  content: "\e96f";
}
.icon-PG_iMessage-icon:before {
  content: "\e970";
}
.icon-PG_Inbox-icon:before {
  content: "\e971";
}
.icon-PG_Info-icon:before {
  content: "\e972";
}
.icon-PG_Instagram-icon-151:before {
  content: "\e973";
}
.icon-PG_Instagram-icon-160:before {
  content: "\e974";
}
.icon-PG_Instagram-icon-172 .path1:before {
  content: "\e975";
  color: rgb(1, 1, 1);
}
.icon-PG_Instagram-icon-172 .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Instagram-icon-172 .path3:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Integration-icon:before {
  content: "\e978";
}
.icon-PG_Invite-icon:before {
  content: "\e979";
}
.icon-PG_iPad-icon:before {
  content: "\e97a";
}
.icon-PG_Klaviyo-icon:before {
  content: "\e97b";
}
.icon-PG_Laptop-icon:before {
  content: "\e97c";
}
.icon-PG_Left-icon-83:before {
  content: "\e97d";
}
.icon-PG_Left-icon-108:before {
  content: "\e97e";
}
.icon-PG_Link-icon:before {
  content: "\e97f";
}
.icon-PG_LinkedIn-icon:before {
  content: "\e980";
}
.icon-PG_LinkedIn .path1:before {
  content: "\e981";
  color: rgb(1, 1, 1);
}
.icon-PG_LinkedIn .path2:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_LinkedIn .path3:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Linktree-icon .path1:before {
  content: "\e984";
  color: rgb(99, 193, 145);
}
.icon-PG_Linktree-icon .path2:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(58, 184, 123);
}
.icon-PG_List-icon:before {
  content: "\e986";
}
.icon-PG_Loading-icon:before {
  content: "\e987";
}
.icon-PG_Mailchimp-icon:before {
  content: "\e988";
}
.icon-PG_Map-location-icon:before {
  content: "\e989";
}
.icon-PG_Menu-icon:before {
  content: "\e98a";
}
.icon-PG_Mic-off-icon:before {
  content: "\e98b";
}
.icon-PG_Mic-on-icon:before {
  content: "\e98c";
}
.icon-PG_Money-icon:before {
  content: "\e98d";
}
.icon-PG_Music-player-icon:before {
  content: "\e98e";
}
.icon-PG_Pause-icon:before {
  content: "\e98f";
}
.icon-PG_Paypal-icon .path1:before {
  content: "\e990";
  color: rgb(33, 156, 216);
}
.icon-PG_Paypal-icon .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(35, 62, 129);
}
.icon-PG_Paypal-icon .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(39, 50, 110);
}
.icon-PG_Percent-icon:before {
  content: "\e993";
}
.icon-PG_Person-fill-icon .path1:before {
  content: "\e994";
  color: rgb(1, 1, 1);
}
.icon-PG_Person-fill-icon .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Person-fill-icon .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Person-fill-icon .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Person-fill-icon .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Person-fill-icon .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Person-outline-icon:before {
  content: "\e99a";
}
.icon-PG_Phone-icon:before {
  content: "\e99b";
}
.icon-PG_Pin-icon:before {
  content: "\e99c";
}
.icon-PG_Play-icon:before {
  content: "\e99d";
}
.icon-PG_Plus-icon:before {
  content: "\e99e";
}
.icon-PG_Podcast-icon:before {
  content: "\e99f";
}
.icon-PG_Poll-icon:before {
  content: "\e9a0";
}
.icon-PG_Pquote-icon .path1:before {
  content: "\e9a1";
  color: rgb(29, 15, 18);
}
.icon-PG_Pquote-icon .path2:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(1, 1, 1);
}
.icon-PG_Question-icon:before {
  content: "\e9a3";
}
.icon-PG_Rating-face-1-icon:before {
  content: "\e9a4";
}
.icon-PG_Rating-face-2:before {
  content: "\e9a5";
}
.icon-PG_Rating-face-3-icon:before {
  content: "\e9a6";
}
.icon-PG_Rating-face-4-icon:before {
  content: "\e9a7";
}
.icon-PG_Reddit-icon:before {
  content: "\e9a8";
}
.icon-PG_Reminded-icon:before {
  content: "\e9a9";
}
.icon-PG_Reminder-icon:before {
  content: "\e9aa";
}
.icon-PG_Reset-icon:before {
  content: "\e9ab";
}
.icon-PG_Return-icon:before {
  content: "\e9ac";
}
.icon-PG_Reverse-icon:before {
  content: "\e9ad";
}
.icon-PG_Right-icon-84:before {
  content: "\e9ae";
}
.icon-PG_Right-icon-109:before {
  content: "\e9af";
}
.icon-PG_Save-icon:before {
  content: "\e9b0";
}
.icon-PG_Search-icon:before {
  content: "\e9b1";
}
.icon-PG_Sendgrid-icon .path1:before {
  content: "\e9b2";
  color: rgb(8, 179, 226);
}
.icon-PG_Sendgrid-icon .path2:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(158, 219, 239);
}
.icon-PG_Sendgrid-icon .path3:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(59, 126, 193);
}
.icon-PG_Sendgrid-icon .path4:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(8, 179, 226);
}
.icon-PG_Sendgrid-icon .path5:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(158, 219, 239);
}
.icon-PG_Sendgrid-icon .path6:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(11, 158, 218);
}
.icon-PG_Sendgrid-icon .path7:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(59, 126, 193);
}
.icon-PG_Settings-icon:before {
  content: "\e9b9";
}
.icon-PG_Share-icon:before {
  content: "\e9ba";
}
.icon-PG_Shareout-icon:before {
  content: "\e9bb";
}
.icon-PG_Site-icon:before {
  content: "\e9bc";
}
.icon-PG_Smiley-fill-icon .path1:before {
  content: "\e9bd";
  color: rgb(1, 1, 1);
}
.icon-PG_Smiley-fill-icon .path2:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Smiley-icon:before {
  content: "\e9bf";
}
.icon-PG_Sort-icon:before {
  content: "\e9c0";
}
.icon-PG_Sort-icon1:before {
  content: "\e9c1";
}
.icon-PG_Soundcloud-icon:before {
  content: "\e9c2";
}
.icon-PG_Spotify-icon:before {
  content: "\e9c3";
}
.icon-PG_Star-icon:before {
  content: "\e9c4";
}
.icon-PG_Strikethrough-icon:before {
  content: "\e9c5";
}
.icon-PG_Subscriber-icon:before {
  content: "\e9c6";
}
.icon-PG_Team-icon:before {
  content: "\e9c7";
}
.icon-PG_Theater-icon:before {
  content: "\e9c8";
}
.icon-PG_Ticket-icon:before {
  content: "\e9c9";
}
.icon-PG_Ticket-multi-fill-icon:before {
  content: "\e9ca";
}
.icon-PG_Ticket-multi-icon:before {
  content: "\e9cb";
}
.icon-PG_Time-icon:before {
  content: "\e9cc";
}
.icon-PG_Trash-icon:before {
  content: "\e9cd";
}
.icon-PG_Twitch-icon-148:before {
  content: "\e9ce";
  color: #775ca7;
}
.icon-PG_Twitch-icon-164:before {
  content: "\e9cf";
}
.icon-PG_Twitter-icon-152 .path1:before {
  content: "\e9d0";
  color: rgb(255, 255, 255);
}
.icon-PG_Twitter-icon-152 .path2:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(67, 156, 214);
}
.icon-PG_Twitter-icon-161:before {
  content: "\e9d2";
}
.icon-PG_Twitter-icon-171 .path1:before {
  content: "\e9d3";
  color: rgb(1, 1, 1);
}
.icon-PG_Twitter-icon-171 .path2:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Twitter-icon-171 .path3:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Up-icon-85:before {
  content: "\e9d6";
}
.icon-PG_Up-icon-107:before {
  content: "\e9d7";
}
.icon-PG_Variant-icon:before {
  content: "\e9d8";
}
.icon-PG_Video-off-icon:before {
  content: "\e9d9";
}
.icon-PG_Video-on-icon:before {
  content: "\e9da";
}
.icon-PG_View-off-icon:before {
  content: "\e9db";
}
.icon-PG_View-on-icon:before {
  content: "\e9dc";
}
.icon-PG_Vimeo-icon .path1:before {
  content: "\e9dd";
  color: rgb(33, 183, 235);
}
.icon-PG_Vimeo-icon .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Volume-icon:before {
  content: "\e9df";
}
.icon-PG_Volume-mute-icon:before {
  content: "\e9e0";
}
.icon-PG_Warning-icon:before {
  content: "\e9e1";
}
.icon-PG_Watch-fill-icon:before {
  content: "\e9e2";
}
.icon-PG_Watch-icon:before {
  content: "\e9e3";
}
.icon-PG_Whatsapp-icon:before {
  content: "\e9e4";
}
.icon-PG_YouTube-icon-146 .path1:before {
  content: "\e9e5";
  color: rgb(237, 34, 36);
}
.icon-PG_YouTube-icon-146 .path2:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_YouTube-icon-168:before {
  content: "\e9e7";
}
.icon-PG_Zoom-icon .path1:before {
  content: "\e9e8";
  color: rgb(72, 143, 204);
}
.icon-PG_Zoom-icon .path2:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG_Zoom-icon .path3:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-PG-06:before {
  content: "\e9eb";
}
.icon-PG-21:before {
  content: "\e9ec";
}
.icon-PG-22:before {
  content: "\e9ed";
}
.icon-PG-115:before {
  content: "\e9ee";
}
.icon-PG-116 .path1:before {
  content: "\e9ef";
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path3:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path4:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path5:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path6:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path7:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(33, 33, 32);
}
.icon-PG-116 .path8:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-PG-117:before {
  content: "\e9f7";
}
.icon-PG-143:before {
  content: "\e9f8";
}
