

body {
  margin: 0;
  font-family: 'Nue Plak', 'Extended-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  text-underline-offset: 2px;
}

.ql-snow .ql-picker.ql-size {
  width: 150px !important;
}

div.ql-container.ql-snow {
  border: none;
}

div.ql-toolbar.ql-snow {
  border: none;
}

.quill,
.ql-toolbar {
  background-color: #f2f2f2;
}

select > option {
  font-weight: normal;
  /* display: block; */
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.CalendarDay__selected {
  background-color: #cdff00 !important;
  border: 1px double #cdff00 !important;
  color: #000 !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background-color: #000 !important;
  border: 1px double #000 !important;
  color: #fff !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}

li.rc-time-picker-panel-select-option-selected {
  background: #cdff00 !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.rc-time-picker-panel-input-wrap {
  display: none !important;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.container {
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;
}

.padding-container {
  width: 100%;
  max-width: calc(2200px + 48px + 48px);
  padding-left: 48px;
  padding-right: 48px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 960px) {
  .padding-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.item-fadein {
  -webkit-animation: fadein 0.6s linear forwards;
  animation: fadein 0.6s linear forwards;
}
.item-fadeout {
  display: flex;
  align-items: center;
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

