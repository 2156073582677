/*--------------------------------

playground-icons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'playground-icons';
  src: url('Assets/fonts/playground-icons.ttf');
  src: url('Assets/fonts/playground-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* --------------------------------

icons

-------------------------------- */
.icon {
  display: inline-block;
  font: normal normal normal 32px/1 'playground-icons';
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  font icons
-------------------------*/

.icon-add::before {
  content: "\ea01";
}

.icon-airplay::before {
  content: "\ea02";
}

.icon-android::before {
  content: "\ea03";
}

.icon-announce::before {
  content: "\ea04";
}

.icon-arrow-back::before {
  content: "\ea05";
}

.icon-arrow-next::before {
  content: "\ea06";
}

.icon-article::before {
  content: "\ea07";
}

.icon-bell::before {
  content: "\ea08";
}

.icon-browser::before {
  content: "\ea09";
}

.icon-calendar::before {
  content: "\ea0a";
}

.icon-camera::before {
  content: "\ea0b";
}

.icon-caption::before {
  content: "\ea0c";
}

.icon-carrot-down::before {
  content: "\ea0d";
}

.icon-carrot-left::before {
  content: "\ea0e";
}

.icon-carrot-right::before {
  content: "\ea0f";
}

.icon-carrot-up::before {
  content: "\ea10";
}

.icon-cc::before {
  content: "\ea12";
}

.icon-chat::before {
  content: "\ea11";
}

.icon-check-off::before {
  content: "\ea13";
}

.icon-check-on::before {
  content: "\ea14";
}

.icon-check::before {
  content: "\ea15";
}

.icon-checkbox-off::before {
  content: "\ea16";
}

.icon-checkbox-on::before {
  content: "\ea17";
}

.icon-clicks::before {
  content: "\ea18";
}

.icon-close::before {
  content: "\ea19";
}

.icon-clubhouse::before {
  content: "\ea1a";
}

.icon-code::before {
  content: "\ea1b";
}

.icon-collapse::before {
  content: "\ea1c";
}

.icon-color::before {
  content: "\ea1d";
}

.icon-customize::before {
  content: "\ea1e";
}

.icon-date::before {
  content: "\ea1f";
}

.icon-download::before {
  content: "\ea20";
}

.icon-drag::before {
  content: "\ea21";
}

.icon-duplicate::before {
  content: "\ea22";
}

.icon-edit::before {
  content: "\ea23";
}

.icon-elipses::before {
  content: "\ea24";
}

.icon-email::before {
  content: "\ea25";
}

.icon-external::before {
  content: "\ea26";
}

.icon-fb::before {
  content: "\ea27";
}

.icon-filter::before {
  content: "\ea28";
}

.icon-font-bold::before {
  content: "\ea29";
}

.icon-font-colorsvg::before {
  content: "\ea2a";
}

.icon-font-italic::before {
  content: "\ea2b";
}

.icon-font-size::before {
  content: "\ea2c";
}

.icon-font-underline::before {
  content: "\ea2d";
}

.icon-forward::before {
  content: "\ea2e";
}

.icon-fullscreen::before {
  content: "\ea2f";
}

.icon-globe::before {
  content: "\ea30";
}

.icon-grid::before {
  content: "\ea31";
}

.icon-handle::before {
  content: "\ea32";
}

.icon-home::before {
  content: "\ea33";
}

.icon-image::before {
  content: "\ea34";
}

.icon-info::before {
  content: "\ea35";
}

.icon-insights::before {
  content: "\ea36";
}

.icon-instagram::before {
  content: "\ea37";
}

.icon-ipad::before {
  content: "\ea38";
}

.icon-laptop::before {
  content: "\ea3a";
}

.icon-link::before {
  content: "\ea39";
}

.icon-linkedin::before {
  content: "\ea3b";
}

.icon-list::before {
  content: "\ea3c";
}

.icon-loading::before {
  content: "\ea3d";
}

.icon-map-location::before {
  content: "\ea3e";
}

.icon-map-pin::before {
  content: "\ea3f";
}

.icon-menu::before {
  content: "\ea40";
}

.icon-mic-off::before {
  content: "\ea41";
}

.icon-mic-on::before {
  content: "\ea42";
}

.icon-money::before {
  content: "\ea43";
}

.icon-p-justify::before {
  content: "\ea44";
}

.icon-p-left::before {
  content: "\ea45";
}

.icon-p-quote::before {
  content: "\ea46";
}

.icon-p-right::before {
  content: "\ea47";
}

.icon-pause::before {
  content: "\ea48";
}

.icon-percent::before {
  content: "\ea49";
}

.icon-person::before {
  content: "\ea4a";
}

.icon-phone::before {
  content: "\ea4b";
}

.icon-plus::before {
  content: "\ea4c";
}

.icon-podcast::before {
  content: "\ea4d";
}

.icon-question::before {
  content: "\ea4e";
}

.icon-reminder-on::before {
  content: "\ea4f";
}

.icon-reminder::before {
  content: "\ea50";
}

.icon-reset::before {
  content: "\ea51";
}

.icon-return::before {
  content: "\ea52";
}

.icon-reverse::before {
  content: "\ea53";
}

.icon-save-fill::before {
  content: "\ea54";
}

.icon-save::before {
  content: "\ea55";
}

.icon-search::before {
  content: "\ea56";
}

.icon-settings::before {
  content: "\ea57";
}

.icon-share::before {
  content: "\ea58";
}

.icon-shareout::before {
  content: "\ea59";
}

.icon-site::before {
  content: "\ea5a";
}

.icon-smiley::before {
  content: "\ea5b";
}

.icon-sort::before {
  content: "\ea5c";
}

.icon-soundcloud::before {
  content: "\ea5d";
}

.icon-spotify::before {
  content: "\ea5e";
}

.icon-star-fill::before {
  content: "\ea5f";
}

.icon-star::before {
  content: "\ea60";
}

.icon-strikethrough::before {
  content: "\ea61";
}

.icon-subscriber::before {
  content: "\ea62";
}

.icon-team::before {
  content: "\ea63";
}

.icon-theater::before {
  content: "\ea64";
}

.icon-ticker-negative::before {
  content: "\ea65";
}

.icon-ticker-positive::before {
  content: "\ea66";
}

.icon-ticket-multi::before {
  content: "\ea67";
}

.icon-ticket::before {
  content: "\ea68";
}

.icon-time::before {
  content: "\ea69";
}

.icon-trash::before {
  content: "\ea6a";
}

.icon-twitch::before {
  content: "\ea6b";
}

.icon-twitter::before {
  content: "\ea6c";
}

.icon-video-off::before {
  content: "\ea6d";
}

.icon-video-on::before {
  content: "\ea6e";
}

.icon-view-off::before {
  content: "\ea6f";
}

.icon-view-on::before {
  content: "\ea70";
}

.icon-volume-mute::before {
  content: "\ea71";
}

.icon-volume::before {
  content: "\ea72";
}

.icon-warning::before {
  content: "\ea73";
}

.icon-watch::before {
  content: "\ea74";
}

.icon-youtube::before {
  content: "\ea75";
}

