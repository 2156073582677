

.react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    position: relative;
    display: inline-block;
    margin: 0 auto;
    /* font-family: 'Aktiv Grotesk'; */
}
.react-add-to-calendar__wrapper {
    zoom: 1;
    cursor: pointer;
}
.react-add-to-calendar__button {
    /* padding: 10px; */
    display: block;
    border-radius: 3px;
    position: relative;
    /* color: #0073E7 !important; */
    /* padding-left: 20px; */
    padding-bottom: 0px;
}

.react-add-to-calendar__button--light {
    background-color: #fff;
}
.react-add-to-calendar__icon--right {
    padding-left: 5px;
}
.react-add-to-calendar__icon--left {
        color: #707070;
    font-size: 20px;

}
.react-add-to-calendar__dropdown {
    position: absolute;
    z-index: 2000;
    bottom: 22px;
    border-radius: 0px;
    left: -145px;
    width: 180px;
    padding: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);;
    border: 1px solid #000;
    background-color: #fff;
    text-align: left;
}
.react-add-to-calendar__dropdown ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.react-add-to-calendar__dropdown ul li  {
    height: 35px;
    width: 100%;
    box-sizing: border-box;
    transition: 250ms linear;
}
.react-add-to-calendar__dropdown ul li:hover {
    background-color: #CEFA6C;
    height: 35px;
    width: 100%;
}
.react-add-to-calendar__dropdown ul li a {
    color: #000;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    align-items: center;
}
.react-add-to-calendar__dropdown ul li a i {
    padding-right: 10px;
    padding-top: 6px;
}
